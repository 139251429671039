import { helpers} from 'vuelidate/lib/validators'

export const fileType = file => {
 // console.log("File type:", file);
  if (typeof file === "string" || file==null) {
  //  console.log("file.type is string:", file);
    return true;
  }
  if (file.type === undefined) {
 //   console.log("validation", file);
    return false;
  }
  
  //This part for files of all type of image type
//  console.log("@@file.type", file.type);
  return file.type.includes("image");

  // //This part for files of types: jpeg,png,jpg,gif,pdf,csv,doc,docx,xls,xlsx
  //   let fileType=file.name.split('.').pop();
  //   console.log('fileType:',fileType)
  //   let expectedFileTypes= 'jpeg,png,jpg,gif,pdf,csv,doc,docx,xls,xlsx'.split(',');
  //   return expectedFileTypes.includes(fileType);
};

export const alpha = helpers.regex('alpha', /^[a-zA-Z ]+$/)

export const chooseOption =  value => value != null

